<template>
  <h3 class="step-section-name">Dodaj sekcję</h3>
  <p class="step-section-description">Wybierz kategorię nowej sekcji</p>

  <v-select
      v-model="selectedCategory"
      :items="sectionCategories"
      :menu-props="{ closeOnContentClick: true }"
      class="section-category-select"
      label="Kategoria"
      @change="addSection"
  >
    <template #selection="{ item }">
      <v-list-item-title>{{ categoryDict[item.value] }}</v-list-item-title>
    </template>

    <template #item="{ item }">
      <v-list-item @click="selectItem(item)">
        <v-list-item-title>{{ categoryDict[item.value] }}</v-list-item-title>
      </v-list-item>
    </template>
  </v-select>

<v-btn class="add-section-button" @click="addSection">
    <span>Dodaj sekcję</span>
    <v-icon>mdi-plus</v-icon>
  </v-btn>
</template>

<script>
import {computed, defineComponent, shallowRef} from 'vue';
import { useMemorialProfileStore } from '@/stores/memorial-profile';

export default defineComponent({
  props: {
    sections: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedCategoryProp: {
      type: String,
      default: ''
    },
  },
  emits: ['section-added'],
  setup(props) {
    const categoryDict = {
      education: 'Edukacja',
      work: 'Praca',
      achievements: 'Osiągnięcia',
      interests: 'Zainteresowania',
      important_events: 'Ważne wydarzenia',
      additional_description: 'Dodatkowy opis',
      family: 'Rodzina',
      gallery: 'Galeria',
    };

    const allSectionCategories = shallowRef(Object.keys(categoryDict));
    const selectedCategory = shallowRef(null);

    const sectionCategories = computed(() => {
      if (Array.isArray(props.sections) && props.sections.length > 0) {
        const addedSectionKeys = props.sections.map(section => section.key);
        return allSectionCategories.value.filter(category => !addedSectionKeys.includes(category));
      } else {
        return allSectionCategories.value;
      }
    });

    return {
      categoryDict,
      sectionCategories,
      selectedCategory,
    };
  },
  methods: {
    selectItem(item) {
      this.selectedCategory = item.value;
    },
    addSection() {
      if (!this.selectedCategory) {
        return;
      }

      const memorialProfileStore = useMemorialProfileStore();
      memorialProfileStore.addSectionState(this.selectedCategory);
      this.$emit('section-added');
      this.selectedCategory = null;
    }
  }
});
</script>

<style lang="scss" scoped>
.section-category-select {
  margin: 24px 0 8px;
}

.add-section-button {
  height: 48px !important;
  width: 100%;
  border-radius: 8px;
  background-color: rgba(#171717, 0.1);
  padding: 12px;
  box-shadow: none;

  &:hover {
    box-shadow: none;
    color: #000000;
  }
}
</style>
