<template>
  <MemProfCreationWrapper current-step="Krok 1 z 3: Widoczność profilu">
    <template #step-indicators>
      <span class="step-indicator active"></span>
      <span class="step-indicator"></span>
      <span class="step-indicator"></span>
    </template>

    <div class="step-content">
      <h3 class="step-title">Widoczność profilu</h3>

      <v-divider class="step-header-separator"></v-divider>

      <p class="step-description">Zdecyduj na jakich zasadach ma się wyświetlać tworzony przez Ciebie profil</p>

      <v-radio-group v-model="selectedVisibility" class="radio-group" row>
        <v-row>
          <v-col>
            <v-radio :disabled="true" value="public">
              <template #label>
                <p class="radio-label">Profil publiczny</p>
              </template>
            </v-radio>

            <div class="description">
              Widoczny publicznie, każdy posiadający link lub skanujący QR Code może obejrzeć historię życia
              upamiętnianej osoby.
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-radio :disabled="true" value="private">
              <template #label>
                <p class="radio-label">Profil prywatny</p>
              </template>
            </v-radio>

            <div class="description">
              Zabezpieczony hasłem dostępu, które zdecydujesz komu udostępnić.
            </div>
          </v-col>
        </v-row>
      </v-radio-group>
    </div>
  </MemProfCreationWrapper>
</template>

<script>
import MemProfCreationWrapper from "@/components/MemorialProfileCreationForm/MemProfCreationWrapper.vue";
import { useMemorialProfileStore } from '@/stores/memorial-profile';
import { computed } from 'vue';

export default {
  components: { MemProfCreationWrapper },
  emits: ['next-step', 'previous-step'],
  setup() {
    const store = useMemorialProfileStore();

    const selectedVisibility = computed({
      get: () => store.memorialProfile.isPrivate ? 'private' : 'public',
      set: (value) => {
        store.updateProfileState({
          isPrivate: value === 'private'
        });
      }
    });

    return {
      selectedVisibility,
      memorialProfile: computed(() => store.memorialProfile),
    };
  },
};
</script>

<style lang="scss" scoped>
.description {
  font-size: 0.875rem;
  color: #666;
  padding-left: 40px;
  padding-bottom: 16px;
  margin-top: -10px;
}

.v-col {
  padding: 0;
}
</style>
